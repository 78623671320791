import colors from 'public/colorsV2.json';
import serviceTypes from 'public/serviceTypesV6.json';

export interface ServiceTypeData {
  id: string;
  slug: string;
  name: string;
  color: keyof typeof colors;
  divisions: string[];
}

export const serviceType = (serviceTypes as ServiceTypeData[]).reduce(
  (mapping: Record<string, string>, { slug }) => {
    return {
      ...mapping,
      [slug.toUpperCase()]: slug
    };
  },
  {}
);

export const serviceTypeNames = (serviceTypes as ServiceTypeData[]).reduce(
  (mapping: Record<string, string>, { slug, name }) => {
    return {
      ...mapping,
      [slug]: name
    };
  },
  {}
);

export const deprecatedServiceTypes = [serviceType.PAVING, serviceType.SEALING, serviceType.QUICK];

export type ServiceType = keyof Record<keyof typeof serviceType, string>;
